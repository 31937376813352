import React from "react";

import styled from "styled-components";


const Bar = styled.div`
  position: absotute;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    padding: 5px;
    img {
      width: 70px;
    }
    h1 {
      font-size: 15px;
    }
  }
`;

const ErrorPage = () => {
    
  
  return (
    <>
      <Bar>
        <div className="logo">
          <a href="#" target="_blank" rel="noreferrer">
            <img src="/amvineslogo.png" alt="bahonLogo" width="120" height="50" />
          </a>
        </div>

        <div className="tracker">
          <h1 style={{ color: "#635f5e" }}>Parcel Tracking</h1>
        </div>
      </Bar>
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        {" "}
        <img src="/nothing.gif" alt="img" height="300" width="200" />{" "}
      </div>
      <h1 style={{ textAlign: "center", fontSize: "24px" }}>
        Invalid Tracking ID
      </h1>
    </>
  );
};

export default ErrorPage;
